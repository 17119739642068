import React from "react";
import logo from "../assets/brand_logo.png";
import logodark from "../assets/brand_logo_dark.png";
import playjslogolight from "../assets/playjs_logo_light.png";
import playjslogodark from "../assets/playjs_logo_dark.png";
import { connect } from "react-redux";
import { addCell } from "../redux/actions";
import WebFont from "webfontloader";

import {
  FaSave,
  FaPlus,
  FaCut,
  FaCopy,
  FaPaste,
  FaTrashAlt,
  FaCaretSquareRight,
} from "react-icons/fa";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
function Header({
  selectedCell,
  cells,
  addCell,
  copyCell,
  cutCell,
  pasteCell,
  moveCellUp,
  moveCellDown,
  deleteCell,
  save,
  clearConsole,
  changeFontStyle,
  changeFontSize,
  changeTheme,
  toggleLineNumbers,
  showLineNumbers,
  fontFamily,
  fontSize,
  theme,
}) {
  const availableFonts = [
    "Roboto Mono",
    "Inconsolata",
    "Source Code Pro",
    "IBM Plex Mono",
    "Ubuntu Mono",
    "PT Mono",
    "Fira Mono",
    "Courier Prime",
    "Cousine",
    "Anonymous Pro",
    "JetBrains Mono",
    "Oxygen Mono",
    "Fira Code",
    "DM Mono",
  ];

  return (
    <nav className={`header ${theme.toLowerCase() === "light" ? "" : "dark"}`}>
      <div className="container">
        <div className="header-wrapper">
          <img
            className="logo"
            src={theme.toLowerCase() === "light" ? logo : logodark}
            alt="logo"
          />
          <div className="options">
            <div className="icon-options">
              <button
                title="Save in BrowserStorage"
                onClick={() => {
                  if (
                    window.confirm(
                      "Your progress will be saved in browser storage. Do you wish to continue?"
                    )
                  )
                    save();
                }}
                className="save"
              >
                <FaSave
                  size={18}
                  color={
                    theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                  }
                />
              </button>
              <div className="creation-group">
                <button title="Add" onClick={addCell} className="add">
                  <FaPlus
                    size={18}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
                <button title="Delete" onClick={deleteCell} className="delete">
                  <FaTrashAlt
                    size={18}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
              </div>
              <div className="edit-group">
                <button title="Cut" onClick={cutCell} className="cut">
                  <FaCut
                    size={18}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
                <button title="Copy" onClick={copyCell} className="copy">
                  <FaCopy
                    size={18}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
                <button title="Paste" onClick={pasteCell} className="paste">
                  <FaPaste
                    size={18}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
              </div>
              <div className="move-group">
                <button
                  title="Move Selected Cell Down"
                  onClick={moveCellDown}
                  className="move-cell-down"
                >
                  <TiArrowDownThick
                    size={20}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
                <button
                  title="Move Selected Cell Up"
                  onClick={moveCellUp}
                  className="move-cell-up"
                >
                  <TiArrowUpThick
                    size={20}
                    color={
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                    }
                  />
                </button>
              </div>
              <button
                title="Run"
                onClick={() => {
                  const selectedCellInput = cells.find(
                    (cell) => cell.id === selectedCell
                  ).input;
                  try {
                    clearConsole();
                    Function(selectedCellInput)();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                className="run"
              >
                <FaCaretSquareRight
                  size={18}
                  color={
                    theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa"
                  }
                />
                <span
                  style={{
                    color:
                      theme.toLowerCase() === "light" ? "#4e4e4e" : "#aaaaaa",
                  }}
                >
                  Run
                </span>
              </button>
            </div>
            <div className="font-customizer">
              <label htmlFor="font">Font</label>
              <select
                className={`${theme.toLowerCase() === "light" ? "" : "dark"}`}
                onChange={(e) => {
                  WebFont.load({
                    google: {
                      families: [e.target.value],
                    },
                  });
                  changeFontStyle(e.target.value);
                }}
                value={fontFamily}
                name="font"
                id="font"
              >
                {availableFonts.map((font) => (
                  <option value={font}>{font}</option>
                ))}
              </select>
              <label htmlFor="fontSize">Size</label>
              <select
                value={parseInt(fontSize.split("px")[0])}
                className={`${theme.toLowerCase() === "light" ? "" : "dark"}`}
                onChange={(e) => {
                  changeFontSize(`${e.target.value}px`);
                }}
                name="fontSize"
                id="fontSize"
              >
                {[12, 14, 16, 18, 20, 24, 26, 28, 30].map((size) => {
                  return <option value={size}>{size}</option>;
                })}
              </select>

              <label htmlFor="theme">Theme</label>
              <select
                className={`${theme.toLowerCase() === "light" ? "" : "dark"}`}
                value={theme}
                onChange={(e) => {
                  changeTheme(e.target.value);
                }}
                name="theme"
                id="theme"
              >
                <option value="Light">Light</option>
                <option value="Dark">Dark</option>
              </select>

              <label htmlFor="toggleLines">Line Numbers</label>
              <select
                className={`${theme.toLowerCase() === "light" ? "" : "dark"}`}
                value={showLineNumbers}
                onChange={(e) => {
                  toggleLineNumbers(e.target.value);
                }}
                name="toggleLines"
                id="toggleLines"
              >
                <option value="on">On</option>
                <option value="off">Off</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedCell: state.app.selectedCell,
    cells: state.app.cells,
    fontFamily: state.custom.fontFamily,
    fontSize: state.custom.fontSize,
    theme: state.custom.theme,
    showLineNumbers: state.custom.showLineNumbers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCell: () => {
      dispatch(addCell());
    },
    deleteCell: () => {
      dispatch({ type: "DELETE_CELL" });
    },
    cutCell: () => {
      dispatch({ type: "CUT_CELL" });
    },
    copyCell: () => {
      dispatch({ type: "COPY_CELL" });
    },
    pasteCell: () => {
      dispatch({ type: "PASTE_CELL" });
    },
    moveCellUp: () => {
      dispatch({ type: "MOVE_CELL_UP" });
    },
    moveCellDown: () => {
      dispatch({ type: "MOVE_CELL_DOWN" });
    },
    save: () => {
      dispatch({ type: "SAVE_PROGRESS" });
    },
    clearConsole: () => {
      dispatch({ type: "CLEAR_CONSOLE" });
    },
    changeFontStyle: (font) => {
      dispatch({ type: "CHANGE_FONT_STYLE", payload: font });
    },
    changeFontSize: (fontSize) => {
      dispatch({ type: "CHANGE_FONT_SIZE", payload: fontSize });
    },
    changeTheme: (theme) => {
      dispatch({ type: "CHANGE_THEME", payload: theme });
    },
    toggleLineNumbers: (value) => {
      dispatch({ type: "TOGGLE_LINE_NUMBERS", payload: value });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
