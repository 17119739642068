import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-crimson_editor";

import { connect } from "react-redux";
// import { originalConsoleLog } from "../logger/log";
// import { originalConsoleClear } from "../logger/clear";
import { Hook, Console, Decode, Unhook } from 'console-feed'



class CellFC extends React.Component {

  // const [isFocused, setIsFocused] = useState(false);
  // const [isExecuting, setisExecuting] = useState(false);
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
      isExecuting: false,
      logs: []
    }
    this.commands = [
      {
        name: "execute-code",
        bindKey: { win: "Ctrl-Enter", mac: 'Command-Enter' },
        exec: (editor) => {
          this.props.clearConsole();
          this.setState({ isExecuting: true })
          try {
            Function(editor.getValue())();
            this.setState({ isExecuting: false })
          } catch (err) {
            console.error(err);
          }
        },
      },
    ];

  }


  componentDidMount() {
    Hook(window.console, (log) => {
      // this.setState(({ logs }) => ({ logs: [...logs, Decode(log)] }))
      if (this.props.id === this.props.selectedCell) {
        this.props.executeCell(this.props.id, [Decode(log)])
      }
    })
  }


  render() {
    // alert('Called render')

    const {
      selectedCell,
      setSelectedCell,
      id,
      input,
      output,
      error,
      setInputForCell,
      fontFamily,
      fontSize,
      theme,
      executionCount,
      showLineNumbers,
    } = this.props;

    const { isExecuting, isFocused } = this.state

    return (
      <div
        className={`cell wrapper ${selectedCell === id
          ? isFocused
            ? theme.toLowerCase() === "light"
              ? "wrapper-focus"
              : "wrapper-focus dark"
            : theme.toLowerCase() === "light"
              ? "wrapper-blur"
              : "wrapper-blur dark"
          : "wrapper-cell-unselected"
          }`}
      >
        <div className="label">
          In [{" "}
          <span
            style={
              executionCount === 0
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
          >
            {isExecuting ? "*" : executionCount}
          </span>{" "}
        ] :
      </div>
        <div className="editor">
          <AceEditor
            wrapEnabled={true}
            onFocus={() => {
              setSelectedCell(id);
              // setIsFocused(true);
              this.setState({ isFocused: true })

            }}
            onBlur={() => {
              // setIsFocused(false);
              this.setState({ isFocused: false })
              // Unhook(window.console)
            }}
            value={input}
            onChange={(code) => {
              setInputForCell(id, code);
            }}
            width="100%"
            showPrintMargin={false}
            highlightActiveLine={false}
            showGutter={showLineNumbers === "off" ? false : true}
            fontSize={fontSize}
            minLines={1}
            maxLines={Infinity}
            className="jseditor"
            style={{
              fontFamily: `'${fontFamily}', 'Consolas'`,
              fontSize,
              border:
                theme.toLowerCase() === "light"
                  ? "2px solid #eeeeee"
                  : "2px solid #aaaa",
              borderRadius: "3px",
              background: theme.toLowerCase() === "light" ? "#f7f7f7" : "",
            }}
            mode="javascript"
            theme={theme.toLowerCase() === "light" ? "tomorrow" : "monokai"}
            name={`jseditor_${id}`}
            commands={this.commands}
            editorProps={{ $blockScrolling: true }}
          ><button>Som</button></AceEditor>
          {output.length > 0 && <div style={{
            backgroundColor: '#242424',
            border: theme.toLowerCase() === 'light' ? '2px solid rgb(238, 238, 238)' : '2px solid rgba(170, 170, 170, 0.667)',
            borderTop: 'none', width: '100%'
          }}>
            <Console
              styles={{
                BASE_FONT_FAMILY: 'monospace',
                BASE_FONT_SIZE: '16px',
                // OBJECT_VALUE_STRING_COLOR: '#D0273D',
                LOG_RESULT_COLOR: '#000000',
                // BASE_COLOR: theme.toLowerCase() === 'light' ? '#000000' : '#ffffff',
                // LOG_COLOR: theme.toLowerCase() === 'light' ? '#000000' : '#ffffff',
                // BASE_BACKGROUND_COLOR: 'transparent',
                // LOG_BACKGROUND: 'transparent',
              }}
              logs={output} variant={"dark"} />
          </div>}
        </div>
      </div>
    );

  }
}


const mapStateToProps = (state) => {
  return {
    fontFamily: state.custom.fontFamily,
    fontSize: state.custom.fontSize,
    theme: state.custom.theme,
    showLineNumbers: state.custom.showLineNumbers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCell: (id) => {
      dispatch({ type: "SET_SELECTED_CELL", payload: id });
    },
    setInputForCell: (id, input) => {
      dispatch({ type: "SET_INPUT_FOR_CELL", payload: { id, input } });
    },
    clearConsole: () => {
      dispatch({ type: "CLEAR_CONSOLE" });
    },
    executeCell: (id, logs) => {
      dispatch({ type: 'EXECUTE_CELL', payload: { cellId: id, output: logs } })
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CellFC);
