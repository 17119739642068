import { v4 as uuidv4 } from "uuid";
import { originalConsoleLog } from "../../logger/log";

const initialState = {
  cells: [],
  selectedCell: null,
  clipboard: {},
};

if (JSON.parse(localStorage.getItem('cells')) !== null && JSON.parse(localStorage.getItem('cells')).length > 0) {
  initialState.cells = JSON.parse(localStorage.getItem('cells'));
} else {
  let id = uuidv4();
  initialState.cells = [
    {
      id,
      input: "",
      output: [],
      error: "",
      executionCount: 0,
    },
  ]
  initialState.selectedCell = id
}

const executionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CELL": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      cells.splice(indexOfSelectedCell + 1, 0, {
        id: uuidv4(),
        input: "",
        output: [],
        error: "",
        isExecuting: false,
        executionCount: 0,
      });
      return {
        ...state,
        cells: cells,
      };
    }
    case "DELETE_CELL": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      cells.splice(indexOfSelectedCell, 1);
      return {
        ...state,
        cells: cells,
      };
    }
    case "CUT_CELL": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      const clipboard = cells[indexOfSelectedCell];
      cells.splice(indexOfSelectedCell, 1);
      return {
        ...state,
        cells: cells,
        clipboard: clipboard,
      };
    }
    case "COPY_CELL": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      const clipboard = cells[indexOfSelectedCell];
      return {
        ...state,
        clipboard: clipboard,
      };
    }
    case "PASTE_CELL": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );

      cells[indexOfSelectedCell] = { ...state.clipboard, id: uuidv4() };
      return {
        ...state,
        cells: cells,
      };
    }
    case "SET_INPUT_FOR_CELL": {
      const cells = state.cells;
      const index = cells.findIndex((cell) => cell.id === action.payload.id);
      cells[index].input = action.payload.input;
      return {
        ...state,
        cells: cells,
      };
    }
    case "SET_SELECTED_CELL":
      return {
        ...state,
        selectedCell: action.payload,
      };
    case "SET_ERROR": {
      const cells = state.cells;
      const index = cells.findIndex(
        (cell) => cell.id === action.payload.cellId
      );
      if (index !== -1) {
        cells[index].error = action.payload.error;
        cells[index].output = [];
      }

      return {
        ...state,
        cells: cells,
      };
    }
    case "CLEAR_CONSOLE": {
      const cells = state.cells;
      const index = cells.findIndex((cell) => cell.id === state.selectedCell);
      cells[index].output = [];
      return {
        ...state,
        cells: cells,
      };
    }
    case "EXECUTE_CELL": {
      const cells = state.cells;
      const index = cells.findIndex(
        (cell) => cell.id === action.payload.cellId
      );
      // alert(index)
      cells[index].output = [...cells[index].output, ...action.payload.output];
      cells[index].error = "";
      cells[index].executionCount++;
      return {
        ...state,
        cells: cells,
      };
    }
    case "MOVE_CELL_UP": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      if (indexOfSelectedCell !== 0) {
        const temp = cells[indexOfSelectedCell];
        cells[indexOfSelectedCell] = cells[indexOfSelectedCell - 1];
        cells[indexOfSelectedCell - 1] = temp;
      }
      return {
        ...state,
        cells: cells,
      };
    }
    case "MOVE_CELL_DOWN": {
      const cells = state.cells;
      const indexOfSelectedCell = cells.findIndex(
        (cell) => cell.id === state.selectedCell
      );
      if (indexOfSelectedCell !== cells.length - 1) {
        const temp = cells[indexOfSelectedCell];
        cells[indexOfSelectedCell] = cells[indexOfSelectedCell + 1];
        cells[indexOfSelectedCell + 1] = temp;
      }
      return {
        ...state,
        cells: cells,
      };
    }

    case "SAVE_PROGRESS": {
      localStorage.setItem("cells", JSON.stringify(state.cells));
      return state;
    }
    default:
      return state;
  }
};

export default executionReducer;
