import React from "react";
import "./App.css";
import CellFC from "./components/CellFC";
import { v4 as uuidv4 } from "uuid";
import Header from "./components/Header";
import { connect } from "react-redux";
import webfontloader from "webfontloader";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCell: null,
      cells: [{ id: uuidv4() }],
    };
  }

  componentDidMount() {
    webfontloader.load({
      google: {
        families: [this.props.fontFamily],
      },
    });
  }

  setIsActive = (id) => {
    this.setState({ selectedCell: id });
  };

  addCell = () => {
    this.setState({
      cells: [...this.state.cells, { id: uuidv4() }],
    });
  };

  render() {
    return (
      <div
        id="jupyter-app"
        className={`jupyter-app ${
          this.props.theme.toLowerCase() === "light" ? "" : "dark"
        }`}
      >
        <Header />
        <div
          className={`app ${
            this.props.theme.toLowerCase() === "light" ? "" : "dark"
          }`}
        >
          {this.props.cells.cells.map((cell) => (
            <CellFC
              selectedCell={this.props.cells.selectedCell}
              key={cell.id}
              {...cell}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cells: state.app,
    theme: state.custom.theme,
    fontFamily: state.custom.fontFamily,
  };
};

export default connect(mapStateToProps, null)(App);
