const initialState = JSON.parse(localStorage.getItem("custom")) || {
  fontFamily: "Cousine",
  fontSize: "16px",
  theme: "Light",
  showLineNumbers: "off",
};

const customizeReducer = (state = initialState, action) => {
  if (action.type === "CHANGE_FONT_STYLE") {
    return { ...state, fontFamily: action.payload };
  } else if (action.type === "CHANGE_FONT_SIZE") {
    return { ...state, fontSize: action.payload };
  } else if (action.type === "CHANGE_THEME") {
    return { ...state, theme: action.payload };
  } else if (action.type === "TOGGLE_LINE_NUMBERS") {
    return { ...state, showLineNumbers: action.payload };
  } else if (action.type === "SAVE_PROGRESS") {
    localStorage.setItem("custom", JSON.stringify(state));
    return state;
  } else {
    return state;
  }
};

export default customizeReducer;
