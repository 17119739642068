import { createStore } from "redux";
import rootReducer from "../reducers";

const env = process.env.NODE_ENV;

const store = createStore(
  rootReducer,
  env !== "production"
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    : ""
);

export default store;
