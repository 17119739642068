import { combineReducers } from "redux";
import executionReducer from "./executionReducer";
import customizeReducer from "./customizeReducer";

const rootReducer = combineReducers({
  app: executionReducer,
  custom: customizeReducer,
});

export default rootReducer;
