export const executeCell = (cellId, output) => {
  return {
    type: "EXECUTE_CELL",
    payload: { cellId, output },
  };
};

export const addCell = () => {
  return {
    type: "ADD_CELL",
  };
};
